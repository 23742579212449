import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Block from '../components/block'

const StyledSection = styled('section')({
    
});

const WorkPage = () => {

    return (
        <Layout>
            <Block>
                <StyledSection>
                    <h1>Work</h1>
                    <p>Coming Soon</p>
                </StyledSection>
            </Block>
        </Layout>
    )   
}

export default WorkPage